export default class Collapsible {
  constructor(el) {
    this.collapsible = el;
    this.collapsibleTrigger = el.querySelector('.collapsible-trigger');
    this.attacheListener();
  }

  attacheListener = () => {
    this.collapsibleTrigger.addEventListener('click', (e) => {
      e.preventDefault();
      if (e.target === this.collapsibleTrigger) {
          this.collapsible.classList.toggle('expand');
       }
    })
  }

}


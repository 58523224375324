import Choices from 'choices.js'
export default class Jschoice {
  constructor(el) {
    this.CheckForJsChoice()
  }

  CheckForJsChoice = () => {
    if (document.querySelector('.js-choice')) {
      document.querySelectorAll('.js-choice').forEach((selector, index) => {
        var choice = new Choices(selector, {
          resetScrollPosition: false,
          removeItemButton: true,
          allowHTML: true,
          searchResultLimit: 10})
      })
    }

    if (document.querySelector('.js-choice-original-order')) {
      document.querySelectorAll('.js-choice-original-order').forEach((selector, index) => {
        var choice = new Choices(selector, {
          resetScrollPosition: false,
          removeItemButton: true,
          allowHTML: true,
          shouldSort: false,
          searchResultLimit: 10})
      })
    }
  }

}

export default class Pills {
  constructor(el) {
    this.pills = el;
    this.filters = el.querySelectorAll('.nav-link');
    this.items = document.querySelectorAll('.list-group-item');
    this.content = el.querySelector('.list-group');

    this.attacheListener();
  }

  attacheListener = () => {
    this.pills.addEventListener('click', (e) => {

      // e.preventDefault();
      if (e.target.hasAttribute('data-type') && e.target.classList.contains('nav-link')) {
        this.filterList(e.target.getAttribute('data-type'));
        this.pills.querySelector('.active').classList.remove('active');
        e.target.classList.add('active');
      }
    })
  }

  filterList = (filterValue) => {

    this.items.forEach(element => {


      if (element.getAttribute('data-type') !== filterValue && filterValue !== "all") {
        element.classList.add('hidden');
        element.setAttribute('aria-hidden', true);
      } else {
        element.classList.remove('hidden');
        element.setAttribute('aria-hidden', false);
      }

    });
  }
}


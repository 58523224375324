export default class ReplyAvailability{
  constructor(el) {
    this.replyAvailability = el;
    this.btn_interested = document.getElementById('btn-interested')
    this.btn_not_interested = document.getElementById('btn-not_interested')
    this.appointement_form = document.getElementById('form-interested')
    this.decline_form = document.getElementById('form-not_interested')
    this.attacheListener();
  }

  attacheListener = () => {
    this.btn_interested.addEventListener('click', (e) => {
      this.appointement_form.classList.remove('hidden')
      this.decline_form.classList.add('hidden')
      this.btn_interested.classList.add('btn-primary')
      this.btn_interested.classList.remove('btn-outline-primary')
      this.btn_not_interested.classList.remove('btn-primary')
      this.btn_not_interested.classList.add('btn-outline-primary')
    })
    this.btn_not_interested.addEventListener('click', (e) => {
      this.appointement_form.classList.add('hidden')
      this.decline_form.classList.remove('hidden')
      this.btn_interested.classList.remove('btn-primary')
      this.btn_interested.classList.add('btn-outline-primary')
      this.btn_not_interested.classList.add('btn-primary')
      this.btn_not_interested.classList.remove('btn-outline-primary')
    })
  }
}

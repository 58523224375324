export default class ListNav {
  constructor(el) {
    this.list = el
    this.siteNav = document.querySelector('.navbar')
    this.sideNav = document.querySelector('.side-nav')
    this.sideNavContainer = document.querySelector('.side-nav-container')
    this.activeItem = this.list
    this.isSideNavActive = false
    this.init()
  }

  toggleState = e => {
    e.preventDefault()
    var clickTarget = e.target.closest('.list-group-item-content')
    if(clickTarget){
      this.activeItem.classList.remove('active')
      clickTarget.parentNode.classList.add('active')
      this.activeItem = clickTarget.parentNode
    }

    if(!this.sideNavContainer.classList.contains('minimize')){
      this.minimizeNavigations()
    }
  }

  minimizeNavigations = () => {
    this.siteNav.classList.add('navbar--minimize')
    this.sideNavContainer.classList.add('minimize')
  }

  init = () => {
    this.list.addEventListener('click', this.toggleState)
  }

}


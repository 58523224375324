export default class DateForm {
  constructor(el) {

    this.form = el
    this.addButton = this.form.querySelector('.add-date')
    this.datesContainer = this.form.querySelector('.date-choice')
    this.inputNameIndex = 0
    this.pickerList = {}
    this.init()
  }


  createDate = () => {
    this.inputNameIndex += 1
    return document.createRange()
      .createContextualFragment(`<li class="appointment mb-3">
          <input type="text" placeholder="Date" name="date${this.inputNameIndex}" class="date form-control">
          <input type="text" placeholder="Start time" name="start-date${this.inputNameIndex}" class="time time-start form-control">
          <input type="text" placeholder="End time" name="end-date${this.inputNameIndex}" class="time time-end form-control">
          <button type="button" class="close bg-primary"  aria-label="Close">
          <span class="text-light" aria-hidden="true">&times;</span></button></li>`)
  }

  removeDate = e => {
    e.composedPath()[2].remove()
    e.target.removeEventListener('click', removeDate, false)
  }

  addDate = () => {
    const htmlNode = this.createDate()
    const closeBtn = htmlNode.querySelector('.btn-close')

    closeBtn.addEventListener('click', this.removeDate)
    this.datesContainer.appendChild(htmlNode)
    this.initDatePicker()
  }

  timeLimitManagment = (elem, target, limit) => {
    elem.on('set', e => {
      if (e.select) {
        target.set(limit, elem.get('select'))
      } else if ('clear' in event) {
        target.set(limit, false)
      }
    })
  }

  initDatePicker = function () {
    this.pickerList[`date${this.inputNameIndex}`] = $(`[name='date${this.inputNameIndex}']`)
      .pickadate({
        formatSubmit: 'dd-mm-yyyy',
        hiddenName:   true
      })
      .pickadate('picker')

    this.pickerList[`start-date${this.inputNameIndex}`] = $(`[name='start-date${this.inputNameIndex}']`)
      .pickatime({
        formatSubmit: 'HH:i',
        hiddenName:   true
      })
      .pickatime('picker')

    this.pickerList[`end-date${this.inputNameIndex}`] = $( `[name='end-date${this.inputNameIndex}']`)
      .pickatime({
        formatSubmit: 'HH:i',
        hiddenName:   true
      })
      .pickatime('picker')

    this.pickerList[`date${this.inputNameIndex}`].set('min', true)
    // this.pickerList[`end-date${this.inputNameIndex}`].set("min", true);

    this.timeLimitManagment(
      this.pickerList[`end-date${this.inputNameIndex}`],
      this.pickerList[`start-date${this.inputNameIndex}`],
      'max'
    )
  }

  init = () => {
    // this.addButton.addEventListener("click", e => {
    //     e.preventDefault();
    //     this.addDate();
    // });
    this.initDatePicker()
  }

}


import { Controller } from '@hotwired/stimulus'
import { i18n } from '../../src/config/i18n.js'

export default class extends Controller {
  static targets = ['parentLine', 'developBtn']

  showUnitChildren() {
    this.developBtnTarget.dataset.action = 'click->asset-trees--structure#hideUnitChildren'
    this.developBtnTarget.classList.remove('fa-angle-right')
    this.developBtnTarget.classList.add('fa-angle-down')
    fetch(`/units/${this.unitId}/unit_amenities.json`, {
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.csrfToken
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data != '' && data != null && data.length > 0) {
          data.reverse().forEach((amenity) => {
            this.parentLineTarget.insertAdjacentHTML('afterend', this.buildAmenityRow(amenity))
          })
        }
      })
      .then(() => {
        fetch(`/units/${this.unitId}/children_units.json`, {
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': this.csrfToken
          }
        })
          .then(response => response.json())
          .then(data => {
            if (data != '' && data != null && data.length > 0) {
              data.reverse().forEach((unit) => {
                this.parentLineTarget.insertAdjacentHTML('afterend', this.buildUnitRow(unit))
              })
            }
          })
      })
  }

  showAmenityChildren() {
    this.developBtnTarget.dataset.action = 'click->asset-trees--structure#hideAmenityChildren'
    this.developBtnTarget.classList.remove('fa-angle-right')
    this.developBtnTarget.classList.add('fa-angle-down')
    fetch(`/amenities/${this.amenityId}/children.json`, {
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.csrfToken
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data != '' && data != null && data.length > 0) {
          data.reverse().forEach((amenity) => {
            this.parentLineTarget.insertAdjacentHTML('afterend', this.buildAmenityRow(amenity))
          })
        }
      })
  }

  showLocationChildren() {
    this.developBtnTarget.dataset.action = 'click->asset-trees--structure#hideLocationChildren'
    this.developBtnTarget.classList.remove('fa-angle-right')
    this.developBtnTarget.classList.add('fa-angle-down')
    fetch(`/material_locations/${this.locationId}/children.json`, {
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.csrfToken
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data != '' && data != null && data.length > 0) {
          data.reverse().forEach((location) => {
            this.parentLineTarget.insertAdjacentHTML('afterend', this.buildLocationRow(location))
          })
        }
      })
  }

  hideUnitChildren() {
    Array.from(document.querySelector('#unit_structure_table').querySelectorAll('tr'))
      .forEach((line) => {
        if (line.dataset.unitAncestry.split('/').includes(this.unitId)) {
          line.remove()
        }
      })
    this.developBtnTarget.dataset.action = 'click->asset-trees--structure#showUnitChildren'
    this.developBtnTarget.classList.add('fa-angle-right')
    this.developBtnTarget.classList.remove('fa-angle-down')
  }

  hideAmenityChildren() {
    Array.from(document.querySelector('#unit_structure_table').querySelectorAll('tr.amenity'))
      .forEach((line) => {
        if (line.dataset.amenityAncestry.split('/').includes(this.amenityId)) {
          line.remove()
        }
      })
    this.developBtnTarget.dataset.action = 'click->asset-trees--structure#showAmenityChildren'
    this.developBtnTarget.classList.add('fa-angle-right')
    this.developBtnTarget.classList.remove('fa-angle-down')
  }

  hideLocationChildren() {
    Array.from(document.querySelector('#structure_table').querySelectorAll('tr'))
      .forEach((line) => {
        if (line.dataset.locationAncestry.split('/').includes(this.locationId)) {
          line.remove()
        }
      })
    this.developBtnTarget.dataset.action = 'click->asset-trees--structure#showLocationChildren'
    this.developBtnTarget.classList.add('fa-angle-right')
    this.developBtnTarget.classList.remove('fa-angle-down')
  }

  buildAmenityRow(amenity) {
    let toggle = ''
    if (amenity.has_children) {
      toggle = `<i class="fa fa-angle-right fa-xl hover-pointer pe-2 unit-list-collapse"
                   data-asset-trees--structure-target="developBtn" data-action="click->asset-trees--structure#showAmenityChildren"></i>`
    } else {
      toggle = '<i class="fa fa-angle-right fa-xl text-white pe-2 unit-list-collapse"></i>'
    }

    return `
      <tr id="amenity-${amenity.id}"  class="amenity"
                                      data-controller='asset-trees--structure'
                                      data-asset-trees--structure-target='parentLine'
                                      data-depth=${parseInt(this.depth) + 1}
                                      data-unit-ancestry=${amenity.unit_ancestry}
                                      data-unit=${amenity.unit_id}
                                      data-amenity=${amenity.id}
                                      data-amenity-ancestry=${amenity.ancestry}
                                      draggable="true"
                                      data-action="dragstart->asset-trees--drag-and-drop#startDrag
                                                   dragover->asset-trees--drag-and-drop#enterRow
                                                   drop->asset-trees--drag-and-drop#dropRow
                                                   dragleave->asset-trees--drag-and-drop#leaveRow">
        <td class="p-0" style="padding-left: ${(parseInt(this.depth) + 1) * 25}px!important;">
          <div class="p-2">
            ${toggle}
            <i class='fas fa-cogs pe-2'></i> ${amenity.name} ${amenity.internal_identifier ? `(${amenity.internal_identifier})` : ''}
          </div>
        </td>
        <td></td>
        <td class="hide_mobile">
          ${amenity.responsible == null ? '' : amenity.responsible}
        </td>
        <td>
          <div class="d-flex justify-content-end gap-3 align-items-center">
            <a target="_blank" href="/amenities/${amenity.id}">
              <div data-bs-toggle="tooltip" data-bs-placement="top" title="${i18n.t('actions.see')}">
                <i class="fa-regular fa-eye fa-lg text-hh-light"></i>
              </div>
            </a>
            ${this.amenityDropdownBtn(amenity)}
          </div>
        </td>
      </tr>
    `
  }

  amenityDropdownBtn(amenity) {
    let duplicateOption = ''
    if (amenity.beta_access) {
      duplicateOption = `
        <li class="dropdown-item">
          <a data-remote="true" href="/openModal?amenity=${amenity.id}&amp;div=duplicate-equip-content&amp;modal=amenities%2Fpartials%2Fduplicate&amp;modal_id=duplicate-equip-btn">
            <div data-bs-toggle="modal" data-bs-target="#duplicate-equip">
              <i class="fas fa-copy text-center" style="width: 20px"></i>${i18n.t('actions.duplicate')}
            </div>
          </a>
        </li>
      `
    }

    return `
      <div class="dropdown dropstart pe-2">
        <div class="hover-pointer" id="dropdownMenuButtonA${amenity.id}" data-bs-toggle="dropdown" aria-expanded="false">
          <i class="fa-solid fa-ellipsis fa-lg"></i>
        </div>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButtonA${amenity.id}">
          <li class="dropdown-item">
            <a data-remote="true" href="/openModal?amenity=${amenity.id}&amp;div=edit-amenity-content&amp;modal=amenities%2Fedit&amp;modal_id=edit-amenity-btn">
              <div data-bs-toggle="modal" data-bs-target="#edit-amenity">
                <i class="fas fa-pen fa-sm text-center" style="width: 20px"></i>${i18n.t('actions.edit')}
              </div>
            </a>
          </li>
          <li class="dropdown-item">
            <a data-remote="true" href="/openModal?amenity=${amenity.id}&amp;div=create-report-content&amp;modal=reports%2Fpartials%2Fcreate_form&amp;modal_id=create-report-btn">
              <div data-bs-toggle="modal" data-bs-target="#create-report">
                <i class="fa-solid fa-ticket text-center" style="width: 20px"></i>${i18n.t('report.actions.create_report')}
              </div>
            </a>
          </li>
          <li class="dropdown-item">
            <a data-remote="true" href="/openModal?amenity=${amenity.id}&amp;div=new-equip-content&amp;modal=amenities%2Fnew&amp;modal_id=new-equip-btn">
              <div data-bs-toggle="modal" data-bs-target="#new-equip">
                <i class="fas fa-cogs text-center" style="width: 20px"></i>${i18n.t('amenity.actions.add_subequip')}
              </div>
            </a>
          </li>
          ${duplicateOption}
        </ul>
      </div>
    `
  }

  buildLocationRow(location) {
    let toggle = ''
    if (location.has_children) {
      toggle = `<i class="fa fa-angle-right fa-xl hover-pointer pe-2 unit-list-collapse"
                   data-asset-trees--structure-target="developBtn" data-action="click->asset-trees--structure#showLocationChildren"></i>`
    } else {
      toggle = '<i class="fa fa-angle-right fa-xl text-white pe-2 unit-list-collapse"></i>'
    }

    let users = ''
    if (location.restricted_access) {
      users = `
        <td>
          ${location.users.map(user => `<p class='mb-0'>${user.username}</p>`)}
        </td>
      `
    }

    return `
      <tr id="location-${location.id}"
          class="location"
          data-controller='asset-trees--structure'
          data-asset-trees--structure-target='parentLine'
          data-depth=${parseInt(this.depth) + 1}
          data-location-ancestry=${location.ancestry}
          data-location=${location.id}>
        <td class="p-0" style="padding-left: ${(parseInt(this.depth) + 1) * 25}px!important;">
          <div class="p-2">
            ${toggle}
            <i class='fas fa-boxes-stacked pe-2'></i> ${location.name}
          </div>
        </td>
        ${users}
        <td>
          <div class="d-flex justify-content-end gap-3 align-items-center">
            ${this.locationDropdownBtn(location)}
          </div>
        </td>
      </tr>
    `
  }

  locationDropdownBtn(location) {
    return `
      <div class="dropdown dropstart pe-2">
        <div class="hover-pointer" id="dropdownMenuButton${location.id}" data-bs-toggle="dropdown" aria-expanded="false">
          <i class="fa-solid fa-ellipsis fa-lg"></i>
        </div>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton${location.id}">
          <li class="dropdown-item">
            <a data-remote="true" href="/openModal?material_location=${location.id}&amp;div=edit-location-content&amp;modal=material_locations%2Fform&amp;modal_id=edit-location-btn">
              <div data-bs-toggle="modal" data-bs-target="#edit-location">
                <i class="fas fa-pen fa-sm text-center" style="width: 20px"></i>${i18n.t('actions.edit')}
              </div>
            </a>
          </li>
          <li class="dropdown-item">
            <a data-remote="true" href="/openModal?material_location=${location.id}&amp;div=new-location-content&amp;modal=material_locations%2Fpartials%2Fnew&amp;modal_id=new-location-btn">
              <div data-bs-toggle="modal" data-bs-target="#new-location">
                <i class="fas fa-plus text-center" style="width: 20px"></i>${i18n.t('material_location.actions.add_material_location')}
              </div>
            </a>
          </li>
        </ul>
      </div>
    `
  }

  buildUnitRow(unit) {
    let toggle = ''
    if (unit.has_children || unit.has_amenities) {
      toggle = `<i class="fa fa-angle-right fa-xl hover-pointer pe-2 unit-list-collapse"
                   data-asset-trees--structure-target="developBtn" data-action="click->asset-trees--structure#showUnitChildren"></i>`
    } else {
      toggle = '<i class="fa fa-angle-right fa-xl text-white pe-2 unit-list-collapse"></i>'
    }

    return `
      <tr id="unit-${unit.id}"  data-controller='asset-trees--structure'
                                data-asset-trees--structure-target='parentLine'
                                data-depth=${parseInt(this.depth) + 1}
                                data-unit-ancestry=${unit.ancestry}
                                data-unit=${unit.id}
                                draggable="true"
                                data-action="dragstart->asset-trees--drag-and-drop#startDrag
                                             dragover->asset-trees--drag-and-drop#enterRow
                                             drop->asset-trees--drag-and-drop#dropRow
                                             dragleave->asset-trees--drag-and-drop#leaveRow">
          <td class="p-0" style="padding-left: ${(parseInt(this.depth) + 1) * 25}px!important;">
            <div class="p-2">
              ${toggle}
              <i class='fas fa-map-marker-alt pe-2'></i> ${unit.name}
            </div>
          </td>
        <td>${unit.alt_name || ''}</td>
        <td class="hide_mobile">
          ${unit.managers.map(manager => `<p>${manager}</p>`)}
        </td>
        <td>
          <div class="d-flex justify-content-end gap-3 align-items-center">
            <a href="/units/${unit.id}">
              <div data-bs-toggle="tooltip" data-bs-placement="top" title="${i18n.t('actions.see')}">
                <i class="fa-regular fa-eye fa-lg text-hh-light"></i>
              </div>
            </a>
            ${this.unitDropdownBtn(unit)}
          </div>
        </td>
      </tr>
    `
  }

  unitDropdownBtn(unit) {
    let duplicateOption = ''
    if (unit.beta_access) {
      duplicateOption = `
        <li class="dropdown-item">
          <a data-remote="true" href="/openModal?div=duplicate-unit-content&amp;modal=units%2Fpartials%2Fduplicate&amp;modal_id=duplicate-unit-btn&amp;unit=${unit.id}">
            <div data-bs-toggle="modal" data-bs-target="#duplicate-unit">
              <i class="fas fa-copy text-center" style="width: 20px"></i>${i18n.t('actions.duplicate')}
            </div>
          </a>
        </li>
      `
    }

    return `
      <div class="dropdown dropstart pe-2">
        <div class="hover-pointer" id="dropdownMenuButtonA${unit.id}" data-bs-toggle="dropdown" aria-expanded="false">
          <i class="fa-solid fa-ellipsis fa-lg"></i>
        </div>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButtonA${unit.id}">
          <li class="dropdown-item">
            <a data-remote="true" href="/openModal?div=edit-unit-content&amp;modal=units%2Fedit&amp;modal_id=edit-unit-btn&amp;unit=${unit.id}">
              <div data-bs-toggle="modal" data-bs-target="#edit-unit">
                <i class="fas fa-pen fa-sm text-center" style="width: 20px"></i>${i18n.t('actions.edit')}
              </div>
            </a>
          </li>
          <li class="dropdown-item">
            <a data-remote="true" href="/openModal?div=create-report-content&amp;modal=reports%2Fpartials%2Fcreate_form&amp;modal_id=create-report-btn&amp;unit=${unit.id}">
              <div data-bs-toggle="modal" data-bs-target="#create-report">
                <i class="fa-solid fa-ticket text-center" style="width: 20px"></i>${i18n.t('report.actions.create_report')}
              </div>
            </a>
          </li>
          <li class="dropdown-item">
            <a data-remote="true" href="/openModal?div=new-unit-content&amp;modal=units%2Fnew&amp;modal_id=new-unit-btn&amp;unit=${unit.id}">
              <div data-bs-toggle="modal" data-bs-target="#new-unit">
                <i class="fas fa-map-marker-alt text-center" style="width: 20px"></i>${i18n.t('unit.actions.add_subunit')}
              </div>
            </a>
          </li>
          <li class="dropdown-item">
            <a data-remote="true" href="/openModal?div=new-equip-content&amp;modal=amenities%2Fnew&amp;modal_id=new-equip-btn&amp;unit=${unit.id}">
              <div data-bs-toggle="modal" data-bs-target="#new-equip">
                <i class="fas fa-cogs text-center" style="width: 20px"></i>${i18n.t('amenity.actions.add_subequip')}
              </div>
            </a>
          </li>
          ${duplicateOption}
        </ul>
      </div>
    `
  }

  get depth()      { return this.parentLineTarget.dataset.depth    }
  get unitId()     { return this.parentLineTarget.dataset.unit     }
  get amenityId()  { return this.parentLineTarget.dataset.amenity  }
  get locationId() { return this.parentLineTarget.dataset.location }

  get csrfToken()  { return document.head.querySelector('meta[name="csrf-token"]').getAttribute('content') }

}

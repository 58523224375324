import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'deadlineForm'
  ]

  get csrfToken() { return document.head.querySelector('meta[name="csrf-token"]').getAttribute('content') }

  editExpectedDate() {
    var new_date = this.deadlineFormTarget.dataset.newDate
    var report_id = this.deadlineFormTarget.dataset.reportId
    var instances_input = this.deadlineFormTarget.querySelector('input[name="instances"]:checked')
    if (instances_input) {
      var instances_value = instances_input.value
      fetch(`/reports/${report_id}/change_expected_date.json`, {
        headers: { 'Content-Type': 'application/json',
          'X-CSRF-Token': this.csrfToken },
        method: 'PATCH',
        body:   JSON.stringify({ new_date: new_date, instances: instances_value })
      })
        .then(response => response.json())
        .then(data => {
          window.calendar.refetchEvents()
          window.calendar.render()
          this.deadlineFormTarget.closest('.modal-content').querySelector('.btn-close')
            .click()
        })
    }
    else {
      alert('Veuillez sélectionner une option')
    }
  }

  cancelChange() {
    this.deadlineFormTarget.closest('.modal-content').querySelector('.btn-close')
      .click()
    window.calendar.refetchEvents()
    window.calendar.render()
  }

  reloadDiv(elem) {
    var modalDiv = document.getElementById(elem.dataset.div)
    modalDiv.innerHTML = ''
    fetch(`/renderHtmlInDiv?report=${elem.dataset.report}&maintenance_control=${elem.dataset.maintenance}&div=${elem.dataset.div}&html=${elem.dataset.partial}&report_todos=true`, {
      headers: {
        'X-CSRF-Token': this.csrfToken },
      method: 'GET'

    })
      .then(response => response.text())
      .then(data => {
        var html = new DOMParser().parseFromString(data, 'text/html')
        modalDiv.innerHTML = html.querySelector('#modal_content').innerHTML
      })
  }

  editTodoStatus(event) {
    var elem = event.currentTarget
    fetch(`/to_do_items/${elem.dataset.todo}.json`, {
      headers: { 'Content-Type': 'application/json',
        'X-CSRF-Token': this.csrfToken },
      method: 'PATCH',
      body:   JSON.stringify( {to_do_item: {status:       elem.dataset.status,
        closed_by_id: elem.dataset.closedById,
        ended_at:     elem.dataset.endedAt }})
    })
      .then(response => response.json())
      .then(data => {
        window.calendar.refetchEvents()
        window.calendar.render()
        this.reloadDiv(elem)

      })

  }

  showEditTodo(event) {
    var elem = event.currentTarget
    this.reloadDiv(elem)


  }




}

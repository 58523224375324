export default class ChartBuilder {
  constructor(el) {
    this.context = el;
    this.data = JSON.parse(el.getAttribute('data-values'))
    this.colors = [  '#0076BA', '#2DC7FF', '#EAD2AC', '#EABA6B','#00ABE7']
    this.chart_type = el.getAttribute('data-charttype')
    this.drawChart(this.context)
  }

  drawChart = (context) => {
    if (this.chart_type == 'doughnut') {
      let chart = new Chart(context, {
        type: this.chart_type,
        data:this.data,
        options: {
          layout: {
            padding: 20
          },
          legend:{
            position: 'left',
            labels: {
              boxWidth: 8,
              fontFamily: 'futura',
              usePointStyle: true
            }
          },
        }
      })
    }
    else  {
      let chart = new Chart(context, {
        type: this.chart_type,
        data: this.data,
        options: {

        }
      })


    }
  }
}



/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
import '@hotwired/turbo-rails'
import 'jquery'
import $ from 'jquery'
import { I18n } from 'i18n-js'
import translations from '../src/config/translations.json'

window.jQuery = $
window.$ = $

import * as Sentry from '@sentry/browser'

Sentry.init({
  dsn:          'https://2052724d9d78c32a586b2227619eab46@o570099.ingest.sentry.io/4506621488136192',
  environment:  window.env,
  enabled:      window.env === 'production' || window.env === 'staging',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText:   true,
      blockAllMedia: true,
    })
  ]
})

// Fetch user locale from html#lang.
// This value is being set on `app/views/layouts/application.html.erb` and
// is inferred from `ACCEPT-LANGUAGE` header.
window.i18n = new I18n()
window.i18n.store(translations)
window.i18n.defaultLocale = 'en'
window.i18n.enableFallback = true

import Rails from '@rails/ujs'
Rails.start()
require('turbolinks').start()
require('@rails/activestorage').start()

require('jquery')
import '../../../vendor/javascripts/recurring_select.js.erb'

import 'bootstrap'
import { Tooltip } from 'bootstrap'

// import 'mapbox-gl/dist/mapbox-gl.css'; // <-- you need to uncomment the stylesheet_pack_tag in the layout!
// import { initMapbox } from '../plugins/init_mapbox';

import 'chart.js'

import Choices from 'choices.js'

// import 'jszip'
// import 'pdfmake'
// import 'datatables.net-bs4'
// import 'datatables.net-buttons-bs4'
// import 'datatables.net-buttons/js/buttons.colVis.js'
// import 'datatables.net-buttons/js/buttons.html5.js'
// import 'datatables.net-buttons/js/buttons.print.js'
// import 'datatables.net-colreorder-bs4'
// import 'datatables.net-datetime'
// import 'datatables.net-fixedcolumns-bs4'
// import 'datatables.net-fixedheader-bs4'
// import 'datatables.net-responsive-bs4'
// import 'datatables.net-searchbuilder-bs4'
// import 'datatables.net-searchpanes-bs4'
// import 'datatables.net-select-bs4'
import moment from 'moment-timezone'

import ModuleLoader from './abstracts/_moduleLoader'


ModuleLoader

import { Application } from '@hotwired/stimulus'
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers'
import { elementClosest } from '@fullcalendar/core/internal'

window.Stimulus = Application.start()
const context = require.context('../controllers', true, /\.js$/)
Stimulus.load(definitionsFromContext(context))

// window.tooltip = function (el) {
//  const tip = new bootstrap.Tooltip(el)
//  el.addEventListener('mouseleave', function () {
//    tip.hide()
//  })
//}

document.addEventListener('turbolinks:load', () => {
  // Both of these are from the Bootstrap 5 docs
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function(tooltipTriggerEl) {
    return new Tooltip(tooltipTriggerEl)
  })
})

// React on Rails
import './react-bundle.js'


modultest = function () {
  navbar = document.querySelector('.navbar')


  document.body.addEventListener('ajax:beforeSend', function (event) {
    event.detail[1].success = moduleLoading
    minimizeNavigation(event.detail[1].url)
  })

  const moduleLoading = () => {
    const moduleElements = document.querySelectorAll('[data-module]')
    const test = document.querySelector('.appointment-form')
    moduleElements.forEach(element => {
      const name = element.getAttribute('data-module')
      const Module = require(`../components/${name}`).default
      new Module(element)
    })
  }


  const minimizeNavigation = (url) => {
    if (navbar) {
      navbar.classList.add('navbar--minimize')
    }
  }

  moduleLoading()
}

export default class Navigation{
  // constructor(el){
  //   this.navigation = el
  //   this.nav = el.querySelector('.navbar-collapse')
  //   this.togglButton = el.querySelector('.navbar-toggler')
  //   this.attacheListener()
  // }
  // attacheListener = () => {
  //   this.nav.addEventListener('click', (e) => {
  //     if(this.nav.classList.contains('show')){
  //       this.nav.classList.remove('show')
  //       this.togglButton.setAttribute('aria-expanded', 'false')
  //       this.togglButton.classList.add('collapsed')
  //     }
  //   })
  // }
}

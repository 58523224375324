import { Controller } from '@hotwired/stimulus'
export default class extends Controller {
  static targets = [
    'userEquipments',
    'userId',
    'assets',
    'userEquipmentsSelector',
    'assetSelectorMode',
    'assetsBtn',
    'userEquipmentsBtn',
    'unit0',
    'unitRequired',
    'amenityRequired'
  ]

  connect() {
    // On connect, load current_user's equipments and existing reports
    if (this.hasAssetSelectorModeTarget && this.hasAssetsTarget) {
      this.assetSelectorModeTarget.classList.remove('hidden')
      this.assetsTarget.classList.add('hidden')
      this.userEquipmentsTarget.classList.add('hidden')
      this.assetsBtnTarget.classList.remove('btn-primary')
      this.assetsBtnTarget.classList.add('btn-outline-primary')
      this.userEquipmentsBtnTarget.classList.remove('btn-primary')
      this.userEquipmentsBtnTarget.classList.add('btn-outline-primary')
      // If equip or unit is required, show user equip by default
      if (this.amenityRequired || this.unitRequired) {
        this.userEquipmentsTarget.classList.remove('hidden')
        this.userEquipmentsBtnTarget.classList.add('btn-primary')
        this.userEquipmentsBtnTarget.classList.remove('btn-outline-primary')
      }
      this.loadUserEquipments()
    }
  }

  get user_id() {
    return this.userIdTarget.value
  }

  async loadUserEquipments() {
    const data = await this.getUserEquipments(this.user_id)
    if (data.length > 0) {
      this.userEquipmentsSelectorTarget.innerHTML = ''
      this.userEquipmentsSelectorTarget.insertAdjacentHTML('beforeend', `
        <option value="">${window.i18n.t('shared.none')}</option>
      `
      )
      data.forEach(line => {
        this.userEquipmentsSelectorTarget.insertAdjacentHTML('beforeend', `
            <option value="${line.amenity_id}">${line.amenity.name}</option>
          `
        )
      })
      console.log('User equipments loaded')
      if (this.assetsTarget.querySelector('#am_lvl0')) {
        this.assetsTarget.querySelector('#am_lvl0').removeAttribute('required')
      }
      if (this.assetsTarget.querySelector('#level0')) {
        this.assetsTarget.querySelector('#level0').removeAttribute('required')
      }
    } else {
      // Hide user equipment div
      this.userEquipmentsSelectorTarget.innerHTML = ''
      this.userEquipmentsTarget.classList.add('hidden')
      // Remove `required` field on hidden user equipment div
      if (this.amenityRequired) {
        this.userEquipmentsTarget.querySelector('#user_amenity').setAttribute('required', true)
      }
      // Hide mode selector
      this.assetSelectorModeTarget.classList.add('hidden')
      this.assetsTarget.classList.remove('hidden')
    }
  }

  toggleAssets() {
    this.assetsBtnTarget.classList.add('btn-primary')
    this.assetsBtnTarget.classList.remove('btn-outline-primary')
    this.userEquipmentsBtnTarget.classList.remove('btn-primary')
    this.userEquipmentsBtnTarget.classList.add('btn-outline-primary')
    this.assetsTarget.classList.remove('hidden')
    this.userEquipmentsTarget.classList.add('hidden')
    this.userEquipmentsSelectorTarget.value = ''

    // Remove `required` attribute on user equipment field & add to assets fields
    if (this.unitRequired) {
      this.assetsTarget.querySelector('#level0').setAttribute('required', true)
    }
    if (this.amenityRequired) {
      this.assetsTarget.querySelector('#am_lvl0').setAttribute('required', true)
      this.userEquipmentsTarget.querySelector('#user_amenity').removeAttribute('required')
    }
  }

  toggleUserEquipments() {
    this.assetsBtnTarget.classList.remove('btn-primary')
    this.assetsBtnTarget.classList.add('btn-outline-primary')
    this.userEquipmentsBtnTarget.classList.add('btn-primary')
    this.userEquipmentsBtnTarget.classList.remove('btn-outline-primary')
    this.assetsTarget.classList.add('hidden')
    this.unit0Target.parentElement.querySelector('button').click()
    this.userEquipmentsTarget.classList.remove('hidden')

    // Remove `required` attribute on asset fields & add to user equipment field
    if (this.unitRequired) {
      this.assetsTarget.querySelector('#level0').removeAttribute('required')
    }
    if (this.amenityRequired) {
      this.assetsTarget.querySelector('#am_lvl0').removeAttribute('required')
      this.userEquipmentsTarget.querySelector('#user_amenity').setAttribute('required', true)
    }
  }

  getUserEquipments(user_id) {
    return fetch(`/equipment_usages.json?user_id=${user_id}&stop=nil`, {
      method:  'GET',
      headers: { 'Content-Type': 'application/json' }
    })
      .then(response => response.json())
      .then(data => {
        return data
      })
  }

  get unitRequired() {
    return this.unitRequiredTarget.dataset.required === 'true'
  }

  get amenityRequired() {
    return this.amenityRequiredTarget.dataset.required === 'true'
  }

}
